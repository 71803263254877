import axiosInstance from '@/plugins/axios'

export default {
  postCreateCodes(discountId, count, maxCount) {
    const url = `admin/discounts/${discountId}/create-codes`

    const data = {
      count,
      maxCount,
    }
    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteCode(codeId) {
    const url = `admin/codes/${codeId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  deleteDiscountToUser(discountId, userId) {
    const url = `admin/discounts/${discountId}/user/${userId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  patchDiscountToUser(discountId, userId, data) {
    const url = `admin/discounts/${discountId}/user/${userId}`

    return axiosInstance.patch(url, data)
      .then(response => response.data)
  },

  assignUsersToDiscount(discountId, data) {
    const url = `admin/discounts/${discountId}/user`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  approveDiscountCode(discountCodeId) {
    const url = `admin/discount-code/${discountCodeId}/approve`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  declineDiscountCode(discountCodeId) {
    const url = `admin/discount-code/${discountCodeId}/decline`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  patchDiscountCode(codeId, data) {
    const url = `admin/discount-code/${codeId}`

    return axiosInstance.patch(url, data)
      .then(response => response.data)
  },
}
